<template>
    <div ref="do-control--container">
        <Loader v-if="isLoading || checkingIn"
                :text="!isLoading ? 'Laddar upp egenkontroll(er)' : 'Laddar'" />
        <div v-else>
            <b-row>
                <b-col cols="12">
                    <b-alert :show="control.checkedOut"
                             style="padding: 6px 11px;">
                        <b-row>
                            <b-col lg="8" cols="12">
                                <strong v-if="!isLoggedInUserEqualToControlCheckOutBy" style="vertical-align: middle;">Egenkontroller är nedladdad och låst sedan {{control.checkedOutDate | datetime('yyyy-MM-dd')}} av {{control.checkedOutByName}}</strong>
                                <strong v-else style="vertical-align: sub;">
                                    <span v-if="isOffline"><font-awesome-icon icon="signal-slash" /> Du är i offlineläge</span>
                                    <span v-else><font-awesome-icon icon="signal-slash" /> Egenkontrollen är nedladdad</span> <!--Egenkontrollen laddades ned {{control.checkedOutDate | datetime('yyyy-MM-dd')}}-->
                                </strong>
                            </b-col>
                            <b-col lg="4" cols="12">
                                <div class="text-right">
                                    <b-button size="sm"
                                              class="border-gray-light text-right"
                                              variant="danger"
                                              v-if="isAvailableToAbortCheckIn"
                                              :disabled="isOffline"
                                              :title="isOffline ? 'Du måste först gå online' : ''"
                                              v-b-tooltip.hover
                                              @click="onAbortCheckIn">Avbryt</b-button>
                                    <!--<b-button size="sm"
                            class="border-gray-light text-right"
                            variant="primary"
                            @click="onCheckIn"
                            :disabled="!isAvailableToCheckIn"
                            :title="!isAvailableToCheckIn ? 'Du måste först gå online' : ''"
                            v-b-tooltip.hover>Checka in</b-button>-->
                                    <ToggleOfflineButton />
                                </div>
                            </b-col>
                        </b-row>
                    </b-alert>
                </b-col>
            </b-row>
            <b-row class="mt-1">
                <b-col md="5" cols="12" order="0" order-sm="0">
                    <h4>
                        <b-button :to="{ name: 'manage-building', params: { id: control.buildingId, tab: 'controls' } }"
                                  v-b-tooltip.hover
                                  variant="light"
                                  class="border-gray-light"
                                  title="Gå till fastighet"
                                  v-if="!control.checkedOut">
                            <font-awesome-icon class="d-sm-inline"
                                               icon="building" />
                        </b-button>
                        {{control.serialNumber}} - {{ control.buildingName }}, {{control.scheduleName}}
                    </h4>
                </b-col>

                <b-col md="5" cols="12" order="2" order-sm="2" class="mb-1">
                    <ControlButtonGroupActions :activePartId="activePartId"
                                               :control="control"
                                               :read-only="isControlDone"
                                               :disabled="isDisabled" />
                </b-col>

                <b-col cols="12" order="4" class="mt-2 mb-2" v-if="isControlDone">
                    <b-button v-if="isControlDone"
                              variant="success"
                              class="mx-auto"
                              block
                              style="pointer-events:none;">
                        <font-awesome-icon icon="clipboard-check" />
                        Avslutad {{control.doneDate | datetime('yyyy-MM-dd')}}
                    </b-button>
                </b-col>

                <b-col md="2" cols="12" order="3" order-sm="3" class="text-right">
                    <b-button-group>
                        <b-button v-b-tooltip.hover
                                  title="Visa lista"
                                  variant="light"
                                  class="border-gray-light"
                                  :class="selectedViewType === 'list' ? 'active' : ''"
                                  @click="onSelectViewType('list')">
                            <font-awesome-icon icon="list" />
                        </b-button>
                        <b-button v-b-tooltip.hover
                                  title="Visa ritning"
                                  variant="light"
                                  class="border-gray-light"
                                  :class="selectedViewType === 'drawing' ? 'active' : ''"
                                  @click="onSelectViewType('drawing')">
                            <font-awesome-icon icon="map-marked-alt" />
                        </b-button>
                    </b-button-group>
                </b-col>
            </b-row>

            <div v-touch:swipe.right="onScrollInTabs"
                 v-touch:swipe.left="onScrollInTabs"
                 class="scrollable-tabs"
                 v-if="!isLoading">
                <!--Chevron right showing if tabs container can be scrolled-->
                <div class="scrollable-icon--container">
                    <font-awesome-icon v-if="tabScrollingIcons.left"
                                       icon="chevron-left"
                                       class="text-black fa-pulse" />
                    <font-awesome-icon v-if="tabScrollingIcons.right"
                                       icon="chevron-right"
                                       pulse
                                       class="text-black fa-pulse" />
                </div>

                <b-tabs ref="tabs"
                        v-model="tabIndex">
                    <b-tab @click="setActivePart(null)">
                        <template #title>
                            Alla
                            <b-badge variant="info">
                                {{ control.numberOfPositions }}
                            </b-badge>
                        </template>
                    </b-tab>

                    <b-tab v-for="part in control.parts"
                           :key="part.id"
                           @click="setActivePart(part.id)">
                        <template #title>
                            {{ part.partName }}
                            <b-badge variant="info">
                                {{ getNumberOfRows(part.id) }}
                            </b-badge>
                        </template>
                    </b-tab>
                </b-tabs>
            </div>
            <!--View-->
            <div>
                <!--List-->
                <div v-if="selectedViewType === 'list'">
                    <b-table :items="positionsByPart"
                             :fields="fields"
                             :sort-by.sync="sortBy"
                             :sort-desc.sync="sortDesc"
                             :sort-direction="sortDirection"
                             stacked="sm"
                             @context-changed="onContextChanged"
                             show-empty
                             fixed
                             small>
                        <template #cell(hasLackFromOtherControl)="row">
                            <div class="text-center"
                                 @click.stop.prevent="onOpenLackSidebar(row.item.hasLackFromOtherControl)">
                                <HasLackFromOtherControlIcon v-if="row.item.hasLackFromOtherControl" />
                            </div>
                        </template>
                        <template #cell(statusId)="row">
                            <div class="text-center">
                                <span v-if="row.item.statusId !== 0"
                                      :title="getControlPositionStatusName(row.item.statusId)">
                                    <ControlPositionStatusBall :status-id="row.item.statusId"
                                                               :title="getControlPositionStatusName(row.item.statusId)" />
                                </span>
                                <b-button-group v-else size="sm">
                                    <b-button variant="success"
                                              @click="setControlPositionStatusToDone(row.item.id, row.item.hasLackFromOtherControl)"
                                              :disabled="isDisabled">
                                        OK
                                    </b-button>
                                    <b-button variant="danger"
                                              @click="onOpenSidebar(row.item)"
                                              :disabled="isDisabled"
                                              :style="{ fontSize: row.item.hasLackFromOtherControl ? '11px' : '', padding: row.item.hasLackFromOtherControl ? '0px 2px' : ''}">
                                        {{row.item.hasLackFromOtherControl ? 'Kvarstår' : 'Brist'}}
                                    </b-button>
                                </b-button-group>
                            </div>
                        </template>
                        <template #cell(concatenatedNumbers)="row">
                            <div class="overflow-hidden">
                                {{ row.item.concatenatedNumbers }}
                            </div>
                        </template>
                        <template #cell(articleName)="row">
                            <div class="overflow-hidden">
                                <img v-if="row.item.fileSymbolId"
                                     style="height: 1.5rem"
                                     :src="getArticleIcon(row.item.articleId)" />
                                {{ row.item.articleName }}
                            </div>
                        </template>
                        <template #cell(description)="row">
                            <div class="overflow-hidden">
                                {{ row.item.description }}
                            </div>
                        </template>
                        <template #cell(buildingPartName)="row">
                            <div class="overflow-hidden">
                                {{ row.item.buildingPartName }}
                            </div>
                        </template>
                        <template #cell(place)="row">
                            <div class="overflow-hidden">
                                {{ row.item.place }}
                            </div>
                        </template>
                        <template #cell(nextRevisionDate)="row">
                            <div class="overflow-hidden">
                                {{ row.item.nextRevisionDateDisplayText}}
                            </div>
                        </template>
                        <template #cell(actions)="row">
                            <a href="#"
                               class="d-none d-sm-block"
                               @click="onOpenSidebar(row.item)"
                               :disabled="isDisabled">
                                <font-awesome-icon class="text-secondary"
                                                   :icon="!isDisabled ? 'pen-square' : 'eye'"
                                                   size="lg" />
                            </a>
                            <b-button class="text-center d-block d-sm-none"
                                      block
                                      @click="onOpenSidebar(row.item)"
                                      :disabled="isDisabled">
                                Välj
                            </b-button>
                        </template>
                        <template #empty="">
                            <h4 class="text-center">Det finns inga positioner</h4>
                        </template>
                    </b-table>
                </div>

                <!--Drawing-->
                <div v-if="activePart && selectedViewType === 'drawing'"
                     id="drawing"
                     class="mt-1"
                     style="overflow: hidden;">
                    <div v-if="!activePart.partDrawingAsBase64"
                         class="text-center">
                        <h4 class="mt-3">
                            <font-awesome-icon icon="map" /> Ritning saknas
                        </h4>
                        <h6>
                            <a @click="selectedViewType = 'list'"
                               href="#">
                                Klicka här för att växla till listläge
                            </a>
                        </h6>
                    </div>
                    <div v-else>
                        <div class="drawing--actions-buttons-group-top">
                            <div class="mb-2">
                                <b-button class="btn btn-sm btn-light btn-outline-gray-light rounded-0 text-dark"
                                          @click="showNumber = !showNumber">
                                    <font-awesome-icon :icon="showNumber ? 'check-square' : 'square'"
                                                       class="text-info" />
                                    Visa nummer
                                </b-button>
                                <b-button-group size="sm" class="ml-1">
                                    <b-button variant="light" class="border-gray-light rounded-0" @click="changePositionScale(-0.1)" title="Mindre symboler">-</b-button>
                                    <b-button variant="light" class="border-gray-light" disabled>{{activePart.symbolSize}}</b-button>
                                    <b-button variant="light" class="border-gray-light rounded-0" @click="changePositionScale(0.1)" title="Större symboler">+</b-button>
                                </b-button-group>
                                <b-form-radio-group v-model="activePart.symbolSize"
                                                    :options="scales"
                                                    size="sm"
                                                    class="ml-1 border-outline-gray-light"
                                                    button-variant="light"
                                                    buttons
                                                    style="max-height: 31px;">
                                </b-form-radio-group>

                                <b-button class="btn btn-sm btn-light btn-outline-gray-light rounded-0 text-dark ml-1"
                                          @click="addPositionMode = !addPositionMode">
                                    <font-awesome-icon :icon="addPositionMode ? 'check-square' : 'square'"
                                                       class="text-info" />
                                    Lägg till position
                                </b-button>
                            </div>
                        </div>
                        <div class="drawing--actions-buttons-group-side">
                            <div>
                                <button class="btn btn-sm btn-light btn-outline-gray-light rounded-0"
                                        @click="onZoomIn">
                                    <font-awesome-icon icon="search-plus" class="text-info" />
                                </button>
                            </div>
                            <div>
                                <button class="btn btn-sm btn-light btn-outline-gray-light rounded-0"
                                        @click="onZoomOut">
                                    <font-awesome-icon icon="search-minus" class="text-info" />
                                </button>
                            </div>

                            <div class="mt-2">
                                <b-button v-b-popover.click.blur.html="`<ul class='pl-3'><li>Klicka på ikon för att redigera</li></ul>`"
                                          class="btn btn-sm btn-light btn-outline-gray-light rounded-0"
                                          title="Info om egenkontroll">
                                    <font-awesome-icon icon="info-circle" class="text-info" />
                                </b-button>
                            </div>
                        </div>

                        <div style="width: 100%;background-color:#fff" @click="closeAllPopovers()">
                            <panZoom ref="svgDrawingPanZoom"
                                     :options="{
                                        minZoom: 0.1,
                                        maxZoom: 3,
                                        smoothScroll: false,
                                        initialZoom: 0.6
                                     }"
                                     @panstart="onPanStart"
                                     @panend="onPanEnd">
                                <svg ref="box"
                                     xmlns="http://www.w3.org/2000/svg"
                                     version="1.1"
                                     :viewBox="`0 0 ${boxSize.width} ${boxSize.height}`"
                                     @click.stop="onAddPosition($event)">
                                    <!--Drawing-->
                                    <image :xlink:href="activePart.partDrawingAsBase64"
                                           height="100%"
                                           width="100%" />

                                    <g v-for="(items, key) in filteredAndGroupedPositionsByNumber"
                                       :key="key">
                                        <g v-if="items.length > 1">
                                            <!--Line between icon and dot-->
                                            <line :x1="items[0].coordinateX"
                                                  :y1="items[0].coordinateY"
                                                  :x2="items[0].coordinateInfoX"
                                                  :y2="items[0].coordinateInfoY"
                                                  :stroke-width="positionSize.line"
                                                  stroke="black" />
                                            <!--Grey dot-->
                                            <circle :cx="items[0].coordinateX"
                                                    :cy="items[0].coordinateY"
                                                    :r="positionSize.radiePoint"
                                                    fill="grey" />
                                            <!--Icon-->
                                            <g :id="`popover-${items[0].id}-wrapper`"
                                               style="cursor:pointer"
                                               @click.stop="onAddPosition($event, items[0].number)">
                                                <!--Grouped Numbers popover-->
                                                <b-popover :ref="`popover-${items[0].id}`"
                                                           :target="`popover-${items[0].id}-wrapper`"
                                                           :title="`Nummer ${items[0].number}`"
                                                           :disabled="addPositionMode"
                                                           customClass="zIndex1000 maxWidth768">
                                                    <template #title>

                                                        <b-button @click="closeAllPopovers" class="close" style="line-height: 0.6" aria-label="Close">
                                                            <span class="d-inline-block" aria-hidden="true">&times;</span>
                                                        </b-button>
                                                        Nummer {{items[0].number}}
                                                    </template>

                                                    <div class="list-group">
                                                        <div v-for="item in items"
                                                             :key="item.id"
                                                             class="list-group-item"
                                                             role="button"
                                                             @click="onOpenSidebar(item)">
                                                            <span v-if="item.statusId !== 0"
                                                                  :title="getControlPositionStatusName(item.statusId)">
                                                                <ControlPositionStatusBall :status-id="item.statusId"
                                                                                           :title="getControlPositionStatusName(item.statusId)" />
                                                            </span>
                                                            <b-button-group v-else size="sm">
                                                                <b-button variant="success"
                                                                          @click.stop="setControlPositionStatusToDone(item.id, item.setControlPositionStatusToDone)"
                                                                          :disabled="isDisabled">
                                                                    OK
                                                                </b-button>
                                                                <b-button variant="danger"
                                                                          :disabled="isDisabled">
                                                                    {{item.hasLackFromOtherControl ? 'Kvarstår' : 'Brist'}}
                                                                </b-button>
                                                            </b-button-group>

                                                            <div class="d-inline-block"
                                                                 @click.stop="onOpenLackSidebar(item.hasLackFromOtherControl)">
                                                                <HasLackFromOtherControlIcon v-if="item.hasLackFromOtherControl"
                                                                                             class="ml-1"
                                                                                             size="18px" />
                                                            </div>
                                                            <img v-if="item.articleIcon !== null"
                                                                 style="width: 25px"
                                                                 class="ml-1"
                                                                 :src="getArticleIcon(item.articleId)" />
                                                            {{ item.concatenatedNumbers }} - {{ item.articleName }}
                                                            {{ item.place ? '- ' + item.place : '' }}
                                                            {{ item.description ? '- ' + item.description : ''}}
                                                            <span v-if="item.nextRevisionDate">
                                                                - {{item.nextRevisionDate | datetime('yyyy')}}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </b-popover>

                                                <!--Rect-->
                                                <rect :width="positionSize.width"
                                                      :height="positionSize.height"
                                                      :x="items[0].coordinateInfoX - positionSize.width/2"
                                                      :y="items[0].coordinateInfoY - positionSize.width/2"
                                                      :fill="getGroupStatusColor(items)"
                                                      style="cursor:pointer" />
                                                <text :x="items[0].coordinateInfoX"
                                                      :y="items[0].coordinateInfoY"
                                                      text-anchor="middle"
                                                      :font-size="items[0].number >= 100 ? positionSize.fontSizeSmall : positionSize.fontSize"
                                                      :alignment-baseline="items[0].number >= 100 ? 'middle' : 'middle'"
                                                      :dy="items[0].number >= 100 ? '-.4em' : '0.5px'"
                                                      fill="white">
                                                    {{ items[0].number }}
                                                </text>
                                            </g>
                                        </g>

                                        <!--Icon Start-->
                                        <g v-for="item in items"
                                           v-else
                                           :id="`popover-${item.id}`"
                                           :key="item.id"
                                           v-b-popover.hover.html="`${ item.hasLackFromOtherControl !== null ? '<small class=text-danger>Det finns en tidigare brist som ännu inte är åtgärdad!</small><br />' : ''}Nummer: ${item.number}<br />${ item.place !== null ? 'Plats: ' + item.place + '<br />' : ''}Beskrivning: ${item.description}<br/>Status: ${(getControlPositionStatusName(item.statusId))}`"
                                           :v-b-popover="`popover-${item.id}`"
                                           :title="item.articleName"
                                           @click="onOpenSidebar($event, item)"
                                           @touchstart="onOpenSidebar($event, item)"
                                           style="cursor:pointer">
                                            <g>
                                                <!--Line between icon and dot-->
                                                <line :x1="item.coordinateX"
                                                      :y1="item.coordinateY"
                                                      :x2="item.coordinateInfoX"
                                                      :y2="item.coordinateInfoY"
                                                      :stroke-width="positionSize.line"
                                                      stroke="black" />
                                                <!--Grey dot-->
                                                <circle :cx="item.coordinateX"
                                                        :cy="item.coordinateY"
                                                        :r="positionSize.radiePoint"
                                                        fill="grey" />
                                                <!--Icon-->
                                                <g>
                                                    <!--Show number or icon-->
                                                    <g v-if="!showNumber && item.fileSymbolId !== null">
                                                        <image :xlink:href="getArticleIcon(item.articleId)"
                                                               :x="item.coordinateInfoX - positionSize.width/2"
                                                               :y="item.coordinateInfoY - positionSize.height/2"
                                                               :height="positionSize.height"
                                                               :width="positionSize.width"
                                                               style="cursor:pointer" />
                                                        <circle v-if="item.statusId > 0"
                                                                :r="positionSize.radieStatus"
                                                                :cx="item.coordinateInfoX + positionSize.width/2"
                                                                :cy="item.coordinateInfoY - positionSize.height/2"
                                                                :fill="getStatusColor(item.statusId)" />
                                                    </g>
                                                    <g v-else>
                                                        <!--Circle-->
                                                        <circle :r="positionSize.radieCircle"
                                                                :cx="item.coordinateInfoX"
                                                                :cy="item.coordinateInfoY"
                                                                :fill="getStatusColor(item.statusId)"
                                                                style="cursor:pointer" />
                                                        <text :x="item.coordinateInfoX"
                                                              :y="item.coordinateInfoY"
                                                              text-anchor="middle"
                                                              :alignment-baseline="items[0].number >= 100 ? 'middle' : 'middle'"
                                                              :font-size="item.number >= 100 ? positionSize.fontSizeSmall : positionSize.fontSize"
                                                              :dy="item.number >= 100 ? '+.1em' : '0.1em'"
                                                              fill="white">
                                                            {{ item.number }}
                                                        </text>
                                                    </g>
                                                    <font-awesome-icon v-if="item.hasLackFromOtherControl"
                                                                       @click.stop.prevent="onOpenLackSidebar(item.hasLackFromOtherControl)"
                                                                       icon="exclamation-triangle"
                                                                       :x="item.coordinateInfoX - positionSize.width * 0.75"
                                                                       :y="item.coordinateInfoY - positionSize.height * 0.75"
                                                                       style="color:red;"
                                                                       :height="positionSize.height/2"
                                                                       :width="positionSize.width/2" />
                                                </g>
                                            </g>
                                        </g>
                                    </g>
                                </svg>
                            </panZoom>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Edit -->
            <b-sidebar v-if="sidebar.selected"
                       backdrop-variant="dark"
                       backdrop
                       right
                       lazy
                       shadow
                       :visible="sidebar.visible"
                       @hidden="onSidebarHidden">
                <template v-slot:title>
                    <slot name="header">
                        <h4 class="text-center">
                            {{ sidebar.selected.concatenatedNumbers }}
                        </h4>
                    </slot>
                </template>
                <div class="px-3">
                    <!--Data-->
                    <dl>
                        <dt>Beskrivning</dt>
                        <dd style="word-break: break-all">
                            {{ sidebar.selected.description }}
                        </dd>
                        <dt>Plats</dt>
                        <dd style="word-break: break-all">
                            {{ sidebar.selected.place ? sidebar.selected.place : '-' }}
                        </dd>
                        <dt>Artikel</dt>
                        <dd style="word-break: break-all">
                            <ArticleModalViewer v-if="sidebar.selected"
                                                :id="sidebar.selected.articleId"
                                                :title="sidebar.selected.articleName"
                                                :is-offline="control.checkedOut" />
                        </dd>
                        <dt v-if="control.isService">Nästa revision</dt>
                        <dd v-if="control.isService"
                            style="word-break: break-all">
                            {{ sidebar.selected.nextRevisionDate ? sidebar.selected.nextRevisionDateDisplayText : '-' }}<span v-if="!isDisabled"> | </span><a href="#" v-if="!isDisabled" v-b-toggle.set-new-revisionDate class="text-secondary">Sätt nytt</a>
                            <b-collapse id="set-new-revisionDate"
                                        accordion="set-new-revisionDate"
                                        role="tabpanel">
                                <b-form>
                                    <label class="mb-0" for="NewRevisionDateForm">Antal år från idag</label>
                                    <b-form-select id="NewRevisionDateForm"
                                                   label="Antal år från idag"
                                                   :options="['1','2','3','4','5','6','7','8','9','10']"
                                                   v-model="sidebar.setNewRevisionDateForm.years"
                                                   class="mt-2">
                                    </b-form-select>
                                    <!--<b-form-datepicker id="NewRevisionDateForm"
                            v-model="sidebar.setNewRevisionDateForm.newDate"
                            type="date"
                            placeholder="Nytt revisionsdatum"
                            autocomplete="off"
                            :locale="'sv-SE'"
                            label-help="Du kan använda piltangenterna för att navigera"
                            :start-weekday="1"
                            aria-describedby="input-controlNext-help-block" />-->
                                </b-form>

                                <div class="d-flex justify-content-between mt-2">
                                    <b-button variant="primary" @click="onSubmitNewRevisionDate">Spara</b-button>
                                    <b-button variant="warning" v-b-toggle.set-new-revisionDate>Avbryt</b-button>
                                </div>
                            </b-collapse>
                            <br />
                            <b-alert variant="success"
                                     style="padding: 4px 15px; padding-bottom: 7px;"
                                     :show="sidebar.setNewRevisionDateForm.saved">
                                <small>
                                    <font-awesome-icon icon="check-square" /> Sparat {{sidebar.setNewRevisionDateForm.newDateDisplayDate}}
                                </small>
                            </b-alert>
                        </dd>
                        <dt>
                            Status:
                        </dt>
                        <dd>
                            <ControlPositionStatusBall :status-id="sidebar.selected.statusId"
                                                       :title="sidebar.statusName"
                                                       verticalAlign="top" /> {{sidebar.statusName}}
                        </dd>
                    </dl>
                    <hr />
                    <!--Form-->
                    <b-form @submit.prevent="onSidebarSubmit">
                        <FileUploader ref="fileUploader"
                                      :entity-type="'controlPartPosition' | getEntityTypeIdFromText"
                                      :entity-id="sidebar.selected.id"
                                      :read-only="isControlDone || isDisabled"
                                      :truncateLength="22" />

                        <b-form-group id="input-group-lackDescription"
                                      label="Anteckning"
                                      label-for="input-lackDescription">
                            <b-form-textarea id="input-lackDescription"
                                             v-model="sidebar.form.lackDescription"
                                             type="text"
                                             placeholder="Ange anteckning"
                                             :disabled="sidebar.isSubmitting || sidebar.setNewRevisionDateForm.isOpen || isDisabled"
                                             :readonly="isControlDone"
                                             autocomplete="off"
                                             aria-describedby="input-lackDescription-help-block" />
                            <b-form-text id="input-lackDescription-help-block">
                                <ul class="text-danger mb-0">
                                    <li v-for="message in validationByKey('LackDescription')"
                                        :key="message">
                                        {{ message }}
                                    </li>
                                </ul>
                            </b-form-text>
                        </b-form-group>

                        <b-row class="mb-3" v-if="isControlDone">
                            <b-col sm="12" class="pt-1">
                                <b-form-group id="input-group-fixedStatusDescription"
                                              label="Åtgärdsstatus"
                                              label-for="input-fixedStatusDescription">
                                    <b-form-textarea id="input-fixedStatusDescription"
                                                     v-model="sidebar.selected.fixedStatusDescription"
                                                     type="text"
                                                     :disabled="true"
                                                     :readonly="true" />
                                </b-form-group>

                                <b-form-group id="input-group-fixedDescription"
                                              label="Åtgärdsbeskrivning"
                                              label-for="input-fixedDescription">
                                    <b-form-textarea id="input-fixedDescription"
                                                     v-model="sidebar.selected.fixedDescription"
                                                     type="text"
                                                     :disabled="true"
                                                     :readonly="true" />
                                </b-form-group>
                            </b-col>
                        </b-row>

                        <b-form-group id="input-group-dueDate"
                                      label="Åtgärda brist senast"
                                      label-for="input-dueDate">
                            <b-form-datepicker id="input-dueDate"
                                               v-model="sidebar.form.lackDueDate"
                                               type="date"
                                               placeholder="Åtgärda brist senast"
                                               autocomplete="off"
                                               aria-describedby="input-controlNext-help-block"
                                               :locale="'sv-SE'"
                                               label-help="Du kan använda piltangenterna för att navigera"
                                               :start-weekday="1" />
                        </b-form-group>


                        <b-row class="mb-3" v-if="!isControlDone">
                            <b-col sm="6" class="pt-1">
                                <b-button v-b-tooltip.hover
                                          variant="danger"
                                          type="submit"
                                          class="btn-block"
                                          :disabled="sidebar.isSubmitting || sidebar.setNewRevisionDateForm.isOpen || isDisabled"
                                          :title="sidebar.isSubmitting ? 'Sparar...' : ''"
                                          @click="sidebar.form.statusId = sidebar.selected.hasLackFromOtherControl ? 3 : 1">
                                    <font-awesome-icon v-if="sidebar.isSubmitting"
                                                       icon="spinner"
                                                       spin />
                                    {{sidebar.selected.hasLackFromOtherControl ? 'Kvarstår' : 'Brist'}}
                                </b-button>
                            </b-col>
                            <b-col sm="6" class="pt-1">
                                <b-button v-b-tooltip.hover
                                          variant="success"
                                          type="submit"
                                          class="btn-block"
                                          :disabled="sidebar.isSubmitting || sidebar.setNewRevisionDateForm.isOpen || isDisabled"
                                          :title="sidebar.isSubmitting ? 'Sparar...' : ''"
                                          @click="sidebar.form.statusId = 10">
                                    <font-awesome-icon v-if="sidebar.isSubmitting"
                                                       icon="spinner"
                                                       spin />
                                    OK
                                </b-button>
                            </b-col>
                        </b-row>
                    </b-form>
                </div>
            </b-sidebar>

            <!-- Lack Sidebar -->
            <b-sidebar v-if="lackSidebar.visible"
                       backdrop-variant="dark"
                       backdrop
                       right
                       lazy
                       shadow
                       :visible="lackSidebar.visible"
                       @hidden="onLackSidebarClosed">
                <template v-slot:title>
                    <slot name="header">
                        <h4 class="text-center">
                            Brist
                        </h4>
                    </slot>
                </template>
                <SaveBuildingPositionLacks v-on:positionUpdated="onLackSidebarClosed"
                                           :positionId="lackSidebar.selectedId" />
            </b-sidebar>

            <!--AddPositionSidebar-->
            <AddPosition v-if="addPositionSidebar.isOpen"
                         :position="addPositionSidebar.active"
                         :controlPartId="activePartId"
                         :partId="control.parts.find(x => x.id === activePartId).partId"
                         :isOpen="addPositionSidebar.isOpen"
                         :positions="control.positions"
                         :controlId="control.id"
                         @close="onAddPositionSidebarClose"
                         :number="addPositionSidebar.number" />
        </div>
    </div>
</template>

<script>
    import { get } from '@/helpers/api';
    import { mapGetters, mapState, mapActions } from 'vuex';
    import {
        getDownloadUrlByArticleId,
        getDownloadUrl
    } from '@/helpers/file.helper';
    import { isInViewport } from '@/helpers/viewport.helper';
    import {
        getStatusColor,
        getGroupStatusColor,
        getStatusName
    } from '@/services/control-position-status.service';
    import {
        getPositionSizeObject,
        SCALES
    } from '@/helpers/position-symbol.helper';
    import {
        getNewPositionsInBox,
        getPositionDependingOnEventType
    } from '@/helpers/coordinate.helper';
    import Loader from '@/components/Loader';
    import HasLackFromOtherControlIcon from '@/components/control/HasLackFromOtherControlIcon';
    import FileUploader from '@/components/file/FileUploader';
    import ArticleModalViewer from '@/components/article/ArticleModalViewer';
    import ControlPositionStatusBall from '@/components/control/ControlPositionStatusBall';
    import ControlButtonGroupActions from '@/components/control/ControlButtonGroupActions';
    import SaveBuildingPositionLacks from '@/components/building/SaveBuildingPositionLacks';
    import ToggleOfflineButton from '@/components/ToggleOfflineButton';
    import AddPosition from '@/components/control/AddPosition';

    export default {
        name: 'DoControl',
        components: {
            Loader,
            FileUploader,
            ArticleModalViewer,
            ControlPositionStatusBall,
            HasLackFromOtherControlIcon,
            ControlButtonGroupActions,
            SaveBuildingPositionLacks,
            ToggleOfflineButton,
            AddPosition,
        },
        watch: {
            isLoading(newValue) {
                if (!newValue)
                    this.$nextTick(() => this.onResize());
            }
        },
        data() {
            return {
                addPositionMode: false,
                addPositionSidebar: {
                    isOpen: false,
                    active: null,
                    number: null,
                },
                // LackSidebar.
                lackSidebar: {
                    visible: false,
                    selectedId: null
                },
                // Sidebar.
                sidebar: {
                    visible: false,
                    selected: null,
                    form: {
                        id: null,
                        lackDescription: '',
                        statusId: 0,
                        lackDueDate: new Date(),
                        remainingLackId: null,
                    },
                    isSubmitting: false,
                    setNewRevisionDateForm: {
                        isOpen: false,
                        saved: false,
                        years: 1,
                        newDate: null,
                        //newDateDisplayDate: new Date().toLocaleDateString('sv-SE'),
                        //newDate: new Date(),
                    },
                    statusName: '',
                },
                activePartId: null,
                showNumber: false,
                showHelpWindow: false,
                isPanning: false,
                boxSize: {
                    width: 500,
                    height: 400
                },
                posScale: 1,
                scales: SCALES,
                selectedViewType: 'list',
                tabIndex: 0,
                tabScrollingIcons: {
                    left: false,
                    right: false
                },
                // Table
                sortBy: 'concatenatedNumbers',
                sortDesc: false,
                sortDirection: 'asc'
            };
        },
        computed: {
            ...mapState({
                user: (state) => state.user.user,
                isOffline: (state) => state.user.isOffline,
                control: (state) => state.control.active,
                isLoading: (state) => state.control.isLoading,
                checkingIn: (state) => state.control.isCheckingIn
            }),
            ...mapGetters('user', ['isInRole']),
            id() {
                return parseInt(this.$route.params.id);
            },
            isControlDone() {
                return this.control.status >= 10;
            },
            fields() {
                let fields = [
                    {
                        key: 'hasLackFromOtherControl',
                        label: '',
                        sortable: true,
                        sortDirection: 'desc',
                        thClass: 'text-center',
                        thStyle: { 'width': '25px' }
                    },
                    {
                        key: 'statusId',
                        label: 'Status',
                        sortable: true,
                        sortDirection: 'desc',
                        thClass: 'text-center',
                        thStyle: { 'width': '110px' }
                    },
                    {
                        key: 'concatenatedNumbers',
                        label: '#',
                        sortable: true,
                        sortDirection: 'desc',
                        thStyle: { 'width': '100px' }
                    },
                    {
                        key: 'articleName',
                        label: 'Artikel',
                        sortable: true,
                        sortDirection: 'desc'
                    },
                    {
                        key: 'description',
                        label: 'Beskrivning',
                        sortable: true,
                        sortDirection: 'desc'
                    },
                    { key: 'place', label: 'Plats', sortable: true, sortDirection: 'desc' }
                ];

                if (!this.activePart)
                    fields.push({ key: 'buildingPartName', label: 'Del', sortable: true, sortDirection: 'desc' });

                if (this.control.isService)
                    fields.push({ key: 'nextRevisionDate', label: 'Nästa revision', sortable: true, sortDirection: 'desc' });

                // Adds action buttons to the end of array due to order in table.
                fields.push({
                    key: 'actions',
                    label: '',
                    tdClass: 'text-right table-list--actions--wrapper',
                    thClass: 'text-right',
                    thStyle: { 'width': '30px' }
                });

                return fields;

            },
            positionsByPart() {
                return this.activePart
                    ? this.control.positions.filter(
                        (x) => x.controlPartId === this.activePartId
                    )
                    : this.control.positions;
            },
            filteredAndGroupedPositionsByNumber() {
                return this.control.positions
                    .filter((x) => x.controlPartId === this.activePartId)
                    .groupByKey('number');
            },
            activePart() {
                return this.activePartId
                    ? this.control.parts.find((x) => x.id === this.activePartId)
                    : null;
            },
            positionSize() {
                return getPositionSizeObject(this.activePart.symbolSize);
            },
            isAdmin() {
                if (!this.user || !this.user.roles)
                    return false;
                return this.isInRole('Administrator');
            },
            isLoggedInUserEqualToControlCheckOutBy() {
                if (!this.user || !this.user.id)
                    return false;
                return this.user.id.localeCompare(this.control.checkedOutBy, undefined, { sensitivity: 'base' }) === 0;
            },
            isDisabled() {
                return this.control.checkedOut && !this.isLoggedInUserEqualToControlCheckOutBy;
            },
            isAvailableToCheckIn() {
                return this.isLoggedInUserEqualToControlCheckOutBy && !this.isOffline;
            },
            isAvailableToAbortCheckIn() {
                return this.isAdmin || this.isLoggedInUserEqualToControlCheckOutBy;
            }
        },
        async mounted() {
            if (typeof this.id === 'undefined') {
                alert('Något gick snett, mata route med id');
                return;
            }

            await this.getControl();

            this.$root.$on('bv::collapse::state', (collapseId, isJustShown) => {
                if (collapseId === 'set-new-revisionDate') {
                    this.sidebar.setNewRevisionDateForm.isOpen = isJustShown;

                    //let date = new Date();
                    //date.setFullYear(date.getFullYear() + 1);

                    //this.sidebar.setNewRevisionDateForm.newDate = date.toLocaleDateString('sv-SE');
                }
            });
        },
        created() {
            window.addEventListener('resize', this.onResize);
        },
        destroyed() {
            window.removeEventListener('resize', this.onResize);
        },
        methods: {
            async onAddPositionSidebarClose(addedControlPartPositionId) {
                this.addPositionSidebar.isOpen = false;
                this.addPositionSidebar.active = null;

                if (addedControlPartPositionId)
                    await this.getControlPartPositionId(addedControlPartPositionId);
            },
            async getControlPartPositionId(positionId) {
                await this.fetchControlPartPositionById(positionId);
            },
            onAddPosition(event, number) {
                event.stopPropagation();

                if (!this.addPositionMode)
                    return;

                if (this.isOffline) {
                    alert('Du kan inte lägga till positioner när du är offline.')
                    return;
                }

                // Pause panzoom.
                this.$refs.svgDrawingPanZoom.pause();

                // Get coordinates.
                const newPositions = getNewPositionsInBox(
                    getPositionDependingOnEventType(
                        event,
                        this.$refs.svgDrawingPanZoom.$panZoomInstance.getTransform(),
                        this.$refs.box.getBoundingClientRect().left,
                        this.$refs.box.getBoundingClientRect().top
                    ),
                    0, 0, 0, 0,
                    this.$refs.box.getBoundingClientRect(),
                    this.$refs.svgDrawingPanZoom.$panZoomInstance.getTransform(),
                    this.boxSize
                );

                const newBuildingPartPosition = {
                    coordinateX: newPositions.coordinateX,
                    coordinateY: newPositions.coordinateY,
                    coordinateInfoX: newPositions.coordinateInfoX,
                    coordinateInfoY: newPositions.coordinateInfoY
                };

                this.addPositionSidebar.active = newBuildingPartPosition;
                this.addPositionSidebar.isOpen = newBuildingPartPosition !== null;
                this.addPositionSidebar.number = number ?? null;

                // Resume panzoom.
                this.$refs.svgDrawingPanZoom.resume();
            },
            ...mapActions('control', [
                'get',
                'postPartPosition',
                'setNewRevisionDate',
                'abortCheckIn',
                'checkIn',
                'fetchControlPartPositionById',
            ]),
            /* Lack Sidebar */
            async onOpenLackSidebar(id) {
                if (this.control.checkedOut)
                    return;
                this.lackSidebar.selectedId = id;
                this.lackSidebar.visible = true;
            },
            onLackSidebarClosed() {
                this.lackSidebar.selectedId = null;
                this.lackSidebar.visible = false;
            },
            /* ./ Lack Sidebar */
            /* Sidebar*/
            async onSidebarSubmit() {
                this.sidebar.isSubmitting = true;
                try {
                    await this.postControlPosition(this.sidebar.form).then(() => this.onSidebarHidden());
                } catch (e) {
                    this.sidebar.isSubmitting = false;
                }
            },
            async setControlPositionStatusToDone(id, hasLackFromOtherControl) {
                try {
                    var vm = { id: id, statusId: 10, lackDescription: '', remainingLackId: hasLackFromOtherControl };
                    await this.postControlPosition(vm);
                } catch (e) {
                    //alert('something went wrong :(');
                }
            },
            async postControlPosition(vm) {
                await this.postPartPosition(vm);
                this.sidebar.isSubmitting = false;
            },
            async getControl() {
                await this.get(this.id);
            },
            //async onSubmitNewRevisionDate() {
            //    const buildingPartPositionId = this.sidebar.selected.positionId;
            //    this.sidebar.setNewRevisionDateForm.saved = false;

            //    let newDate = this.sidebar.setNewRevisionDateForm.newDate;

            //    await this.setNewRevisionDate({ buildingPartPositionId: buildingPartPositionId, nextRevisionDate: newDate })
            //        .then(newDate => {
            //            this.sidebar.setNewRevisionDateForm.saved = true;
            //            this.sidebar.setNewRevisionDateForm.newDateDisplayDate = newDate;
            //            this.$root.$emit('bv::toggle::collapse', 'set-new-revisionDate');
            //        })
            //        .catch(x => {
            //            alert('Något gick snett :(')

            //        });
            //},
            async onSubmitNewRevisionDate() {
                const buildingPartPositionId = this.sidebar.selected.positionId;
                await this.setNewRevisionDate({ buildingPartPositionId: buildingPartPositionId, years: this.sidebar.setNewRevisionDateForm.years })
                    .then(async response => {
                        this.sidebar.setNewRevisionDateForm.saved = true;
                        this.sidebar.setNewRevisionDateForm.newDate = response.newDate;
                        this.sidebar.selected.nextRevisionDateDisplayText = response.nextRevisionDateDisplayText;
                        this.sidebar.selected.nextRevisionDate = response.nextRevisionDate;

                        this.$root.$emit('bv::toggle::collapse', 'set-new-revisionDate');
                    })
                    .catch(x => {
                        alert('Något gick snett :(')
                    });
            },
            onOpenSidebar($event, item) {
                if (this.addPositionMode)
                    return this.onAddPosition($event, item.number);

                this.sidebar.selected = item;
                this.sidebar.form.id = item.id;
                this.sidebar.form.lackDescription = item.lackDescription;
                this.sidebar.form.remainingLackId = item.hasLackFromOtherControl;
                this.sidebar.statusName = this.getControlPositionStatusName(item.statusId);
                this.sidebar.visible = true;
                var date = new Date();
                this.sidebar.form.lackDueDate = item.lackDueDate !== null ? item.lackDueDate : new Date(date.setMonth(date.getMonth() + 3));
            },
            onSidebarHidden() {
                this.sidebar = {
                    visible: false,
                    selected: null,
                    form: {
                        id: null,
                        lackDescription: '',
                        statusId: 0,
                        lackDueDate: null
                    },
                    isSubmitting: false,
                    setNewRevisionDateForm: {
                        isOpen: false,
                        years: 1,
                        newDate: new Date(),
                    },
                    statusName: ''
                };
            },
            /* ./ Sidebar*/
            onContextChanged(ctx) {
                if (ctx.perPage > this.totalRows)
                    this.currentPage = 1;
            },
            getControlPositionStatusName(statusId) {
                return getStatusName(statusId);
            },
            validationByKey(code) {
                return this.validationErrors ? this.validationErrors[code] || [] : [];
            },
            setActivePart(part) {
                if (!part)
                    this.selectedViewType = 'list';
                this.activePartId = part;
            },
            onZoomIn() {
                const instance = this.$refs.svgDrawingPanZoom.$panZoomInstance;
                const current = instance.getTransform();
                instance.smoothZoom(current.x, current.y, 1.5);

                this.closeAllPopovers();
            },
            onZoomOut() {
                const instance = this.$refs.svgDrawingPanZoom.$panZoomInstance;
                const current = instance.getTransform();
                instance.smoothZoom(current.x, current.y, 0.75);

                this.closeAllPopovers();
            },
            onPanStart(event) {
                this.disableAllPopovers();
                this.isPanning = true;
            },
            onPanEnd(event) {
                this.isPanning = false;
                this.enableAllPopovers();
            },
            getArticleIcon(articleId) {
                return this.getUrl(articleId);
            },
            getUrl(id) {
                return getDownloadUrlByArticleId(id);
            },
            getUrlByFile(id) {
                return getDownloadUrl(id);
            },
            getStatusColor(statusId) {
                return getStatusColor(statusId);
            },
            getGroupStatusColor(positions) {
                return getGroupStatusColor(positions);
            },
            getNumberOfRows(partId) {
                return this.control.positions.reduce((number, x) => {
                    return x.controlPartId === partId ? number + 1 : number;
                }, 0);
            },
            setScrollingIcons(ref, obj) {
                if (typeof this.$refs[ref] === 'undefined') return;

                let el = this.$refs[ref].$el;

                const firstChild = el.querySelector('li:first-child');
                const isFirstChildVisible = isInViewport(firstChild);
                this[obj].left = !isFirstChildVisible;

                const lastChild = el.querySelector('li:last-child');
                const isLastChildVisible = isInViewport(lastChild);
                this[obj].right = !isLastChildVisible;
            },
            onResize() {
                this.setScrollingIcons('tabs', 'tabScrollingIcons');
            },
            onScrollInTabs() {
                this.setScrollingIcons('tabs', 'tabScrollingIcons');
            },
            closeAllPopovers() {
                this.$root.$emit('bv::hide::popover');
            },
            enableAllPopovers() {
                this.$root.$emit('bv::enable::popover');
            },
            disableAllPopovers() {
                // Close and disable popovers.
                this.closeAllPopovers();
                this.$root.$emit('bv::disable::popover');
            },
            onSelectViewType(viewType) {
                // If no part selected. Default to first in array.
                if (!this.activePart && viewType === 'drawing') {
                    this.setActivePart(this.control.parts[0].id);
                    this.tabIndex = 1;
                }

                this.selectedViewType = viewType;
            },
            changePositionScale(change) {
                let scale = Math.round((this.activePart.symbolSize + change) * 10) / 10
                if (scale <= 0) scale = 0.1;
                if (scale > 3) scale = 3;
                this.setPartSymbolSize(scale);
            },
            setPartSymbolSize(newSize) {
                let activePart = this.control.parts.find((x) => x.id === this.activePartId);
                activePart.symbolSize = newSize;
            },
            async onCheckIn() {
                if (await this.confirm('Detta kommer checka in egenkontrollen')) {
                    try {
                        await this.checkIn(this.control.id);
                        await this.getControl();
                    } catch {
                        alert('Något gick snett. Försök igen');
                    }
                }
            },
            async onAbortCheckIn() {
                if (await this.confirm('Detta kommer ta bort lokala kopian.\nAlla förändringar som eventuellt gjorts lokalt kommer förloras.')) {
                    await this.abortCheckIn()
                    await this.getControl();
                }
            },
            async confirm(message) {
                return await this.$bvModal
                    .msgBoxConfirm(message, {
                        title: 'Är du säker?',
                        size: 'sm',
                        //buttonSize: 'sm',
                        cancelVariant: 'danger',
                        cancelTitle: 'Nej',
                        okTitle: 'Ja',
                        okVariant: 'primary',
                        footerClass: 'p-2 justify-content-between',
                        hideHeaderClose: false,
                        centered: true
                    })
                    .then((confirmed) => {
                        return confirmed
                    });
            }
        }
    };
</script>

<style>
    .custom-file-input ~ .custom-file-label[data-browse]::after {
        content: 'Välj filer';
    }

    .drawing--actions-buttons-group-top {
        z-index: 10;
        position: absolute;
    }

    .drawing--actions-buttons-group-side {
        z-index: 10;
        position: absolute;
        margin-top: 36px;
        width: 20px;
    }
</style>
